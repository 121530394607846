@import 'styles/theme';

.title {
  font-family: map-get($fontFamilies, 'MonumentGrotesk');
  letter-spacing: -0.02em;
  line-height: 1.2;
  font-weight: map-get($fontWeights, 'medium');

  &-30-88 {
    @extend .title;
    font-size: 30px;
    line-height: 1;


    @include from('md') {
      font-size: 88px;
    }
  }

  &-50-148 {
    @extend .title;
    font-size: 50px;

    @include from('md') {
      font-size: 148px;
    }
  }

  &-50-60 {
    @extend .title;
    font-size: 50px;

    @include from('md') {
      font-size: 60px;
    }
  }

  &-50-88 {
    @extend .title;
    font-size: 50px;
    line-height: 1;
    font-weight: map-get($fontWeights, 'medium');

    @include from('md') {
      font-size: 80px;
    }
  }

  &-18 {
    @extend .title;
    font-size: 18px;
  }

  &-30 {
    @extend .title;
    font-size: 30px;
  }
}
