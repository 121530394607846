@import 'styles/theme';

.text {
  font-family: map-get($fontFamilies, 'MonumentGrotesk');
  letter-spacing: -0.01em;
  line-height: 1.1;

  &-13 {
    @extend .text;
    font-size: 13px;
  }

  &-15 {
    @extend .text;
    font-size: 15px;
    line-height: 1.4;
  }

  &-18 {
    @extend .text;
    font-size: 18px;
  }
}
