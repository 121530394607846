@import './reset.scss';
@import './theme';

@font-face {
  font-family: 'MonumentGrotesk';
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/MonumentGrotesk-Regular.woff2') format('woff2'),
    url('/fonts/MonumentGrotesk-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MonumentGrotesk';
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  src: url('/fonts/MonumentGrotesk-Medium.woff2') format('woff2'),
    url('/fonts/MonumentGrotesk-Medium.woff') format('woff');
}

:root {
  --scroll-bar: 0px;
  --scroll-bar-grid: 0px;
  --vh-100: 100vh;
  --theme-color: #{theme($colors, 'white')};
}

html,
body {
  overscroll-behavior-y: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  font-family: map-get($fontFamilies, 'MonumentGrotesk');
  letter-spacing: -0.02em;
}

body {
  overflow: scroll;
  overflow-x: hidden;
  &.loading {
    cursor: wait;
  }

  &.white {
    color: theme($colors, 'black');
    background-color: theme($colors, 'white');
  }

  &.black {
    color: theme($colors, 'white');
    background-color: theme($colors, 'black');
  }
}
