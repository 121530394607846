@import 'styles/theme';

.Separator {
  position: relative;
  width: 100%;
  height: 1px;

  .line,
  .lineContent {
    height: 100%;
    box-sizing: border-box;
  }

  .line {
    position: absolute;
    overflow: hidden;
    width: vw(100);
    height: 100%;

    left: 50%;
    transform: translate(-50%);

    @include applyColumnWidth('padding-left', 1, $mobile);
    @include applyColumnWidth('padding-right', 1, $mobile);

    @include from('md') {
      @include applyColumnWidth('padding-left', 1, $desktop);
      @include applyColumnWidth('padding-right', 1, $desktop);
    }
  }

  .lineContent {
    width: 100%;
    left: 0px;
    top: 0px;
    background-color: currentColor;
    opacity: 0.1;
  }

  &.fullViewportWidth {
    .line {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
