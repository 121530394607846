@import 'styles/theme';

.cta {
  font-family: map-get($fontFamilies, 'MonumentGrotesk');
  letter-spacing: -0.02em;

  &-13 {
    @extend .cta;
    font-size: 13px;
    line-height: 1;
  }

  &-18 {
    @extend .cta;
    font-size: 18px;
    line-height: 1;
  }

  &-30 {
    @extend .cta;
    font-size: 30px;
    line-height: 1;
    font-weight: map-get($fontWeights, 'medium');
  }
}
