@import 'styles/theme';

.DeviceNavigation {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  transition: background-color 0.15s linear;
  z-index: 999;
  * {
    transition: inherit;
  }

  &.black {
    color: theme($colors, 'black');
  }

  .separator {
    position: absolute;
    bottom: 0px;
  }

  .background {
    position: absolute;
    top: 0px;
    left: calc(50% - #{getGridColumnWidth(1, $mobile)});
    transform: translateX(-50%);
    width: vw(100);
    height: 100%;
  }

  .smallScreenPanel {
    background-color: black;
    width: 100%;
    position: absolute;
    top: 49px;
    padding-top: 148px;
    padding-bottom: 19px;

    .link {
      margin-bottom: 0.3em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .content {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    grid-column: 2 / 30;
    height: 49px;

    .linksContainer {
      position: relative;
      display: flex;
      align-items: center;

      .link {
        margin-right: 10px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .logo {
      position: relative;
      display: block;
    }

    .toggleMenuButton {
      span {
        padding: 12px;
        margin: -12px;
      }
    }
  }
}
