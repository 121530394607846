@import 'styles/theme';

.Navigation {
  &.desktop {
    display: none;
    @include from('md') {
      display: grid;
    }
  }
  &.device {
    display: grid;
    @include from('md') {
      display: none;
    }
  }
}
