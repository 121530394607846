@import 'styles/theme';

.DesktopNavigation {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  transition: background-color 0.15s linear;
  z-index: 999;
  * {
    transition: inherit;
  }

  .separator {
    position: absolute;
    bottom: 0px;
  }

  &.black {
    color: theme($colors, 'black');
  }

  .contactUs {
    display: inline-block;
  }

  .background {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: vw(100);
    height: 100%;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 49px;
    grid-column: 2 / 60;

    .linksContainer {
      position: relative;
      display: flex;
      align-items: center;

      .link {
        margin-right: 10px;
        display: flex;
        align-items: center;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .links {
      display: flex;
      align-items: center;
    }

    .logoContainer {
      position: relative;
      overflow: hidden;
      @include applyColumnWidth('margin-right', 1, $desktop);
      width: 210px;
    }

    .panelContainer {
      overflow: hidden;
      left: 0px;
      position: absolute;
      width: 100%;
      height: 49px;
    }

    .animatedLogo {
      position: relative;
      display: block;
      width: 58px;
      margin-right: 15px;
    }

    .logo {
      position: relative;
      display: block;
    }
  }
}
