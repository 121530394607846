@import 'styles/theme';

.BigTitle {
  position: relative;
  display: inline-flex;
  cursor: pointer;

  .word {
    display: inline-block;
    perspective: 1000px;
    will-change: transform;
  }

  .hitbox {
    display: inline-flex;
    position: relative;
  }

  .letter {
    position: relative;
    pointer-events: none;

    &.isAnimating {
      transform-style: preserve-3d;

      .shadowLetter {
        &:nth-child(2) {
          transform: translateZ(-3px);
        }

        &:nth-child(3) {
          transform: translateZ(-6px);
        }
      }
    }

    .shadowLetter {
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }

  .wordsWrapper {
    display: inline-block;
  }

  .cursorHandler {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
  }

  .cursor {
    position: absolute;
    height: 45px;
    width: 45px;
    z-index: 1;
    pointer-events: none;
    opacity: 0;

    .cursorImage {
      position: absolute;
      width: 100%;
      height: 100%;
      background: url('/cursors/toupie.png') center center;
      transform-origin: center center;

      &.isHover {
        animation: rotateCursor linear 0.8s infinite backwards;
      }
    }
  }
}

@keyframes rotateCursor {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
