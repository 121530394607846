@use "sass:math";

@import 'styles/theme';

.ratio-full-width-card {
  padding-bottom: math.div(900, 673) * 100%;

  @include from('md') {
    padding-bottom: math.div(871, 1394) * 100%;
  }
}
