@import 'styles/theme';

.SplittedSection {
  position: relative;

  &.withTopSeparator {
    padding-top: 30px;
  }

  &.withBottomSeparator {
    padding-bottom: 30px;
  }

  .start,
  .end {
    position: relative;
    grid-column: 2 / 30;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .start {
    @include from('md') {
      grid-column: 2 / 31;
    }
  }

  .end {
    @include from('md') {
      grid-column: 32 / 60;
    }
  }

  .separator {
    position: absolute;
    grid-column: 2 / 30;

    @include from('md') {
      grid-column: 2 / 60;
    }
  }

  .bottomSeparator {
    bottom: 0px;
  }

  .topSeparator {
    top: 0px;
  }

  &.withVerticalSeparator {
    .start {
      @include from('md') {
        &:after {
          content: '';
          position: absolute;
          right: 0px;
          top: 0px;
          width: 1px;
          height: 100%;
          background-color: currentColor;
          opacity: 0.1;
          padding: 27px 0px;
          box-sizing: border-box;
        }
      }
    }
  }
}
