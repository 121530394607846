@use "sass:math";

@use "sass:map";

// Shortcut for map-get
@function theme($key, $value) {
  @return map-get($key, $value);
}

// Mixin to generate mobile-first repsonsive breakpoints
@mixin from($screenName) {
  @media screen and (min-width: map-get($screens, $screenName)) {
    @content;
  }
}

// Mixin to convert px to em
@function em($pixels, $context: map-get($fontSizes, 16)) {
  @return #{math.div($pixels, $context)}em;
}

// Mixin for fluid types
@function stripUnit($value) {
  @return math.div($value, $value * 0 + 1);
}

@mixin fluidType($minVw, $maxVw, $minFontSize, $maxFontSize) {
  $u1: unit($minVw);
  $u2: unit($maxVw);
  $u3: unit($minFontSize);
  $u4: unit($maxFontSize);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $minFontSize;
      @media screen and (min-width: $minVw) {
        font-size: calc(
          #{$minFontSize} + #{stripUnit($maxFontSize - $minFontSize)} *
            ((#{vw(100)} - #{$minVw}) / #{stripUnit($maxVw - $minVw)})
        );
      }
      @media screen and (min-width: $maxVw) {
        font-size: $maxFontSize;
      }
    }
  }
}

// Get vw value without scrollBar width
@function vw($vw) {
  @return calc(#{$vw * 1vw} - var(--scroll-bar));
}

// Get vh value from JS
@function vh($vh) {
  @return calc(#{$vh} * var(--vh-100) / 100);
}

// Apply vw to width with fallback
@mixin applyVw($vw) {
  width: #{$vw}vw;
  width: vw($vw);
}

// Apply vh to height with fallback
@mixin applyVh($vh) {
  height: #{$vh}vh;
  height: vh($vh);
}

// Get grid width without gutters & gaps
@function getGridWidthWithoutGuttersAndGaps($size) {
  @return calc(
    #{vw(100)} - (#{theme($size, 'gutter')} * 2) - ((
            #{theme($size, 'columns')} - 1
          ) * #{theme($size, 'gap')})
  );
}

// Get grid max-width without gutters & gaps
@function getGridMaxWidthWithoutGuttersAndGaps($size) {
  @return calc(
    (#{theme($max, 'width')} - var(--scroll-bar-grid)) -
      (#{theme($size, 'gutter')} * 2) -
      ((#{theme($size, 'columns')} - 1) * #{theme($size, 'gap')})
  );
}

// Function to get the width of a column
@function getGridColumnWidth($numberOfColumns, $size) {
  @return calc(
    (
        #{getGridWidthWithoutGuttersAndGaps($size)} / #{theme($size, 'columns')} *
          #{$numberOfColumns}
      ) + (#{theme($size, 'gap')} * (#{$numberOfColumns - 1}))
  );
}

// Function to get the max-width of a column
@function getGridColumnMaxWidth($numberOfColumns, $size) {
  @return calc(
    (
        #{getGridMaxWidthWithoutGuttersAndGaps($size)} / #{theme(
            $size,
            'columns'
          )} * #{$numberOfColumns}
      ) + (#{theme($size, 'gap')} * (#{$numberOfColumns} - 1))
  );
}

// Mixin to get the width of a column
@mixin gridColumnWidth($numberOfColumns: 1, $size) {
  width: getGridColumnWidth($numberOfColumns, $size);
  max-width: getGridColumnMaxWidth($numberOfColumns, $size);
}

// Call to get appropriate grid columns width & max-width properties w/ breakpoint already set
@mixin columns(
  $params: (
    mobile: 12,
    desktop: 12,
  )
) {
  @include gridColumnWidth(map-get($params, 'mobile'), $mobile);

  @include from('md') {
    @include gridColumnWidth(map-get($params, 'desktop'), $desktop);
  }
}

// Apply column width to a property
@mixin applyColumnWidth($propertyName, $numberOfColumns, $size) {
  // ie11 fix css var with calc not working
  #{$propertyName}: calc(#{$numberOfColumns} * 100vw / 48);
  #{$propertyName}: getGridColumnWidth($numberOfColumns, $size);

  @include from('max-grid-width') {
    #{$propertyName}: getGridColumnMaxWidth($numberOfColumns, $size);
  }
}

// Call to set a property to a column value w/ breakpoint already set
@mixin applyColumns($propertyName, $columnsMobile, $columnsDesktop, $size) {
  @include applyColumnWidth($propertyName, $columnsMobile, $mobile);

  @include from('md') {
    @include applyColumnWidth($propertyName, $columnsDesktop, $desktop);
  }
}

// Method to set a value into a propertie from grid columns gaps & gutters
@mixin setPropertyValue(
  $params: (
    'values': $desktop,
    'propertyName': '',
    'gutters': 0,
    'gaps': 0,
    'columns': 0,
  )
) {
  #{map-get($params, 'propertyName')}: calc(
    #{getGridColumnWidth(
        map-get($params, 'columns'),
        map-get($params, 'values')
      )} + (
        #{theme(map-get($params, 'values'), 'gutter')} * #{map-get(
            $params,
            'gutters'
          )}
      ) + (#{theme(map-get($params, 'values'), 'gap')} * #{map-get(
            $params,
            'gaps'
          )})
  );

  @include from('max-grid-width') {
    #{map-get($params, 'propertyName')}: calc(
      #{getGridColumnMaxWidth(
          map-get($params, 'columns'),
          map-get($params, 'values')
        )} + (
          #{theme(map-get($params, 'values'), 'gutter')} * #{map-get(
              $params,
              'gutters'
            )}
        ) + (#{theme(map-get($params, 'values'), 'gap')} * #{map-get(
              $params,
              'gaps'
            )})
    );
  }
}

// Generate padding bottom from ratio presets
@mixin genPaddingBottomFromRatio($ratio) {
  padding-bottom: math.div(1, $ratio) * 100%;
}

// Clear margin for blocks
@mixin clearMarginForBlocks() {
  &:first-child {
    margin-top: 0px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
}

// Generate classes
@mixin generateClassesFromList($list, $propertyName) {
  @each $listItem in $list {
    .#{$propertyName}-#{$listItem} {
      #{$propertyName}: $listItem;
    }
  }
}

// Generate classes from map elements like colors, text ...
@mixin generateClassesFromMap(
  $list,
  $propertyName,
  $altPropertiesName: [],
  $pseudoElements: []
) {
  @each $listKey, $listItem in $list {
    .#{$listKey} {
      #{$propertyName}: $listItem;

      @if altPropertiesName {
        @each $altPropertyName in $altPropertiesName {
          #{$altPropertyName}: $listItem;
        }
      }

      @if pseudoElements {
        @each $pseudoElement in $pseudoElements {
          &::#{$pseudoElement} {
            #{$propertyName}: $listItem;
          }
        }
      }
    }
  }
}

@mixin primaryColor($propertyName) {
  // ie11 fix css var not working
  #{$propertyName}: theme($colors, 'denim');
  #{$propertyName}: var(--primary-color);
}

@mixin secondaryColor($propertyName) {
  // ie11 fix css var not working
  #{$propertyName}: theme($colors, 'persian-blue');
  #{$propertyName}: var(--secondary-color);
}

// Switch method for desktop / mobile components
@mixin switchDesktopMobileClasses($breakpoint: 'md', $display: block) {
  .hideOnSmallScreen {
    display: none;

    @include from($breakpoint) {
      display: $display;
    }
  }
  .hideOnLargeScreen {
    @include from($breakpoint) {
      display: none;
    }
  }
}

// Map to props method to generate export variable from map css to js value
@mixin mapToProps($map, $key: 'toto') {
  @if type-of($map) == map {
    @each $prop, $value in $map {
      @if type-of($value) != map {
        @if inspect($prop) != 'description' {
          #{$key}___#{$prop}: #{inspect($value)};
        }
      } @else {
        @include mapToProps($value);
      }
    }
  }
}

@mixin sticky() {
  position: relative;
  position: sticky;
}

// Debug method which add border to an element
@mixin debug($color: red) {
  border: 1px solid $color;
  box-sizing: border-box;
}
