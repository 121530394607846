@use "sass:map";
@import 'styles/theme';

.InlineCta {
  cursor: pointer;
  white-space: nowrap;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: inherit;
  text-decoration: none;

  // Circle themes
  &.circleTheme-white {
    .innerCircle {
      background-color: map-get($colors, 'white');

      &:nth-child(2) {
        background-color: map-get($colors, 'flamingo');
      }
    }
  }

  &.circleTheme-black {
    .innerCircle {
      background-color: map-get($colors, 'black');

      &:nth-child(2) {
        background-color: map-get($colors, 'flamingo');
      }
    }
  }

  .withLine {
    text-decoration: underline;
  }

  .children {
    position: relative;
    display: inline-block;
  }

  .childrenMask {
    display: inline-block;
    vertical-align: middle;

    &.withNoOverflow {
      overflow: hidden;
    }
  }

  .circle {
    width: 0.5em;
    height: 0.5em;
    display: inline-block;
    vertical-align: middle;
    // position: absolute;
    border-radius: 50%;
    transform-style: preserve-3d;
    margin-left: 0.3em;
    perspective: 1000px;
    // transform: translate(100%);
  }

  .innerCircle {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    background-color: map-get($colors, 'flamingo');

    &:nth-child(1) {
      transform: translateZ(0px);
    }
    &:nth-child(2) {
      transform: translateZ(0.06em);
      background-color: black;
    }
    &:nth-child(3) {
      transform: translateZ(0.12em);
    }
  }

  .animatedArrowMask {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }

  .animatedArrow {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }

  .arrow {
    display: inline;
    width: 1.7em;
    margin-right: 0.6em;

    path {
      fill: currentColor;
    }
  }
}
