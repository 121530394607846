@import 'styles/theme';

.AnimatedLogo {
  .square {
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    width: 50%;
    perspective: 1000px;
    color: inherit;

    &:first-child {
      background-color: currentColor;
    }

    .stripedCircle {
      color: inherit;
      display: block;
      path {
        fill: currentColor;
      }
    }

    .stripedCircleMask {
      overflow: hidden;
      border-radius: 50%;
    }

    .plainCircle {
      width: 100%;
      height: 100%;
      padding-bottom: 100%;
      border-radius: 50%;
    }
  }
}
