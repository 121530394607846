@import 'styles/theme';
@import 'styles/text-presets/title.module';
@import 'styles/text-presets/text.module';

.rich-text {
  @extend .text;

  strong,
  b {
    font-weight: map-get($fontWeights, 'medium');
  }

  &-variant-1 {
    @extend .rich-text;
    font-size: 20px;
    line-height: 1.45;

    p {
      margin-top: 30px;
      margin-bottom: 30px;
      @include clearMarginForBlocks();

      @include from('md') {
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }

    ol {
      list-style: decimal;
      list-style-position: inside;
      counter-reset: orderedList;

      li {
        display: flex;
        flex-grow: 1;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 10px;

        p {
          display: inline-flex;
        }

        &:before {
          counter-increment: orderedList;
          content: counter(orderedList) '.';
          margin-right: 5px;
          font-weight: map-get($fontWeights, 'medium');
        }

        @include from('md') {
          &:before {
            margin-right: 10px;
          }
        }
      }
    }

    :global(.label) {
      display: inline-block;
      font-size: 14px;
      margin-bottom: 10px;
      color: inherit;
      opacity: 0.5;
    }

    :global(.big-title) {
      @extend .title-50-88;
    }

    :global(.rich-text-image) {
      position: relative;
    }

    :global(.medium-title) {
      @extend .title-30;
      line-height: 1.15;
    }
  }

  &-variant-2 {
    @extend .rich-text-variant-1;

    p {
      &:first-child {
        ul {
          margin-top: 0px;
        }
      }
    }

    ul {
      margin-top: -17px;
      @include from('md') {
        margin-top: -12px;
      }

      li {
        position: relative;
        font-size: 13px;
        padding: 13px 0px;

        &:after,
        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 1px;
          background-color: currentColor;
          opacity: 0.1;
          left: 0px;
        }
        &:after {
          top: 0px;
        }
        &:before {
          bottom: 0px;
          display: none;
        }
        &:last-of-type {
          &:before {
            display: block;
          }
        }
      }
    }
  }

  &-variant-3 {
    @extend .rich-text;
    font-size: 18px;
  }
}
