@import 'styles/theme';

.Footer {
  position: relative;
  margin-top: 100px;

  @include from('md') {
    margin-top: 200px;
  }

  .separator {
    position: absolute;
    top: 0px;
  }

  .moreInfos {
    margin-bottom: 22px;
    &:first-child {
      line-height: 0.4;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .splittedSection {
    padding-top: 20px;
    padding-bottom: 24px;

    @include from('md') {
      min-height: 604px;
    }
    
    li {
      margin-bottom: 4px;
    }
  }

  .logoType {
    display: none;
    width: 124px;
    margin-top: auto;

    @include from('md') {
      display: block;
    }
    path {
      fill: currentColor;
    }
  }

  .start {
    margin-bottom: 77px;

    &:last-child {
      margin-bottom: 0px;
    }

    @include from('md') {
      margin-bottom: 0px;
    }
  }

  .socialNetworks {
    order: -1;
    margin-bottom: 22px;
    @include from('md') {
      order: unset;
      margin-bottom: unset;
      margin-top: auto;
    }
  }

  .credit {
    display: inline-block;
    opacity: 0.3;

    @include from('md') {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
