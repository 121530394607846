@use "sass:math";

@import './mixins';
/*
  COLORS
*/
$colors: (
  'black': #000000,
  'dark-gray': #0d0d0d,
  'white': #ffffff,
  'flamingo': #f06327,
  'denim': #1860db,
);

/*
  GRID
*/
$mobile: (
  'columns': 30,
  'gap': 0vw,
  'gutter': 0vw,
);

$desktop: (
  'columns': 60,
  'gap': 0vw,
  'gutter': 0vw,
);

$max: (
  'width-design': 1440px,
  'width': 1920px,
);

/*
  FONTS
*/

$MonumentGrotesk: 'MonumentGrotesk', sans-serif;

$fontFamilies: (
  MonumentGrotesk: $MonumentGrotesk,
);

$fontSizes: (
  12: 12px,
  14: 14px,
  16: 16px,
  18: 18px,
  20: 20px,
  24: 24px,
  26: 26px,
  32: 32px,
  48: 48px,
);

$fontWeights: (
  'normal': normal,
  'medium': 500,
);

// MARGIN AND PADDING
$spacings: (
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  10: 10px,
  12: 12px,
  15: 15px,
  20: 20px,
  25: 25px,
  30: 30px,
  35: 35px,
  40: 40px,
  45: 45px,
  50: 50px,
  55: 55px,
  60: 60px,
  65: 65px,
  70: 70px,
  80: 80px,
  85: 85px,
  90: 90px,
  100: 100px,
  110: 110px,
  120: 120px,
  130: 130px,
  135: 135px,
  140: 140px,
  150: 150px,
  160: 160px,
  180: 180px,
  200: 200px,
  220: 220px,
  250: 250px,
);

/*
  SCREENS
*/
$screens: (
  xxxs: 375px,
  xxs: 480px,
  xs: 576px,
  sm: 640px,
  md: 813px,
  l: 930px,
  lg: 1024px,
  xl: 1280px,
  xxl: map-get($max, 'width-design'),
  'max-grid-width': map-get($max, 'width'),
);

/*
  RATIO
*/
$ratio: (
  '18/25': math.div(18, 25),
  '2/3': math.div(2, 3),
  '179/227': math.div(179, 227),
  '388/227': math.div(388, 227),
  '570/227': math.div(570, 227),
  '180/209': math.div(180, 209),
);

$navigationHeight: (
  'mobile': 80px,
  'desktop': 88px,
);

/*
  MIXINS
*/

@function processSymetricValue($value, $columns) {
  @return (($columns + 2) - $value);
}

// Custom mixin for IE grid symetric columns values 2 / -2x
@mixin symetricColumns($m, $d, $b: 'md') {
  // ie11 fix calc not working
  grid-column-start: $m;
  grid-column-end: processSymetricValue($m, theme($mobile, 'columns'));
  //calc(#{stripUnit(theme($mobile, 'columns'))} + 2 - #{$m});

  @include from($b) {
    // ie11 fix calc not working
    grid-column-start: $d;
    grid-column-end: processSymetricValue($d, theme($desktop, 'columns'));
  }
}
