@import 'styles/theme';

$alertWidth: 100%;
$borderRadius: 7px;

.RegisterLinkAlert {
  width: 0px;
  height: 0px;
}

.LinkAlertCenter {
  z-index: 999;
  list-style: none;
  position: fixed;

  @include applyColumnWidth('bottom', 1, $desktop);
  @include applyColumnWidth('left', 1, $desktop);
  max-height: 100%;

  @include from('md') {
    display: block;
  }
}

.LinkAlert {
  color: map-get($colors, 'white');
  overflow: hidden;
  margin-top: 20px;
  transform-origin: left;
  border-radius: $borderRadius;

  .children {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;

    .childrenContent {
      flex-shrink: 1;
      flex-grow: 1;
    }

    .cta {
      flex-shrink: 0;
      margin-left: 25px;
      text-transform: capitalize;
      text-decoration: underline;
    }
  }

  .link {
    position: relative;
    color: currentColor;
    border-radius: $borderRadius;
    display: inline-block;
    width: 90%;
    padding: 20px;
    box-sizing: border-box;

    @include from('md') {
      padding: 30px;
    }
  }

  .background {
    display: block;
    background-color: map-get($colors, 'denim');
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: $borderRadius;
  }
}

:export {
  alertWidth: $alertWidth;
}
