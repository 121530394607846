@import 'styles/theme';

.base-grid {
  display: grid;
  @include applyVw(100);
  max-width: 100%;
  grid-template-columns: repeat(theme($mobile, 'columns'), 1fr);
  grid-template-rows: 1fr;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @include from('md') {
    grid-template-columns: repeat(theme($desktop, 'columns'), 1fr);
    grid-template-rows: 1fr;
  }

  @include from('max-grid-width') {
    width: theme($max, 'width');
  }
}
