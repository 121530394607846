.Video {
  background-position: center;
  background-size: cover;
  display: block;
}

.Video[poster] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
