@import 'styles/theme';

.MediaWithPlaceholder {
  position: relative;
  background-color: rgba(255,255,255,.06);

  &.withRadius {
    border-radius: 4px;
    overflow: hidden;
    // Border-radius zIndex fix for iOS
    z-index: 1;
  }

  .image {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .imageContainer {
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.2s linear;

    &.isLoaded {
      opacity: 1;
    }
  }

  .video {
    object-fit: contain;
  }

  .imagePlaceholder {
    filter: contrast(1.3);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 1;
    will-change: opacity;
    transition: opacity 0.2s linear;

    &.isLoaded {
      opacity: 0;
    }
  }
}

.placeholder {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 1;
  will-change: opacity;
  transition: opacity 1s ease;
  background-color: theme($colors, 'flamingo');

  &.isLoaded {
    opacity: 0;
  }
}

.cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
