@import 'title.module';
@import 'text.module';
@import 'cta.module';
@import 'input.module';
@import 'rich-text.module';

.inherit {
  font-family: inherit;
  color: inherit;
  text-transform: inherit;
}
