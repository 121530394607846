@import 'styles/theme';

.Page {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
}

.mask {
  position: relative;
  width: 100%;

  &:before {
    content: '';
    display: block;
    top: -1px;
    height: 1px;
  }
}

.animatedNavigation {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.content {
  width: 100%;
  min-height: 100vh;
  margin-top: 171px;

  @include from('md') {
    margin-top: 354px;
  }

  > * {
    &:first-child {
      margin-top: 0;
    }
  }
}

:export {
  maxGridWidth: theme($max, 'width');
}
